import React from "react";

import {
  createUseStyles,
  useNavigate,
  Menu,
  ROUTES,
  useUserPreferences,
  setUserPreferencesState,
  LOCALES,
  // @ts-ignore
} from "@projectdiction/common-react";
// @ts-ignore
import { useTranslation } from "react-i18next";

import LanguageSelectDropdown from "./LanguageSelectDropdown";

const useStyles = createUseStyles({
  root: {
    width: "90%",
  },
});

function SettingsPanelMenu({ toast, opRef }) {
  const classes = useStyles();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const preferences = useUserPreferences();

  if (!preferences) return <div>loading...</div>;

  const handleLanguageChange = async (type, language) => {
    try {
      await setUserPreferencesState({
        ...preferences,
        languages: {
          ...preferences.languages,
          [type]: language,
        },
      });

      opRef.current.hide();

      toast.current.show({
        severity: "success",
        summary: t("settings.messages.language-changed"),
        detail: t("settings.texts.language-changed-detail"),
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: t("settings.messages.language-not-changed"),
        detail: t("settings.texts.language-not-changed-detail"),
      });
    }
  };

  return (
    <Menu
      model={[
        {
          label: t("buttons.open_settings"),
          icon: "pi pi-globe",
          command: () => {
            navigate(ROUTES.SETTINGS);

            opRef.current.hide();
          },
        },
        {
          template: () => {
            return (
              <LanguageSelectDropdown
                toast={toast}
                label={t("settings.known-language")}
                language={preferences.languages.knownLanguage}
                availableLanguages={LOCALES}
                setLanguage={(language) => {
                  handleLanguageChange("knownLanguage", language);
                }}
              />
            );
          },
        },
        {
          template: () => {
            return (
              <LanguageSelectDropdown
                toast={toast}
                label={t("settings.study-language")}
                language={preferences.languages.studyLanguage}
                availableLanguages={LOCALES}
                setLanguage={(language) => {
                  handleLanguageChange("studyLanguage", language);
                }}
              />
            );
          },
        },
        {
          template: () => {
            return (
              <LanguageSelectDropdown
                toast={toast}
                label={t("settings.display-language")}
                language={preferences.languages.displayLanguage}
                availableLanguages={LOCALES}
                setLanguage={(language) => {
                  handleLanguageChange("displayLanguage", language);
                }}
              />
            );
          },
        },
      ]}
      className={classes.root}
    />
  );
}

export default SettingsPanelMenu;
