import React from "react";

import {
  Dropdown,
  // @ts-ignore
} from "@projectdiction/common-react";
// @ts-ignore
import { useTranslation } from "react-i18next";

function LanguageDropdown({
  toast,
  language,
  setLanguage,
  label,
  availableLanguages,
}) {
  const [lanugageInput, setLanguageInput] = React.useState(language);

  const { t } = useTranslation();

  const onChange = async (e) => {
    const _lanugageInput = e.target.value;

    setLanguageInput(_lanugageInput);
    setLanguage(_lanugageInput);
  };

  return (
    <div className="field grid" style={{ margin: "0 5px" }}>
      <label htmlFor="LanguageDropdown4928" className="col-6">
        {label}
        {language !== lanugageInput && <sup>*</sup>}
      </label>
      <div className="col-6">
        <Dropdown
          id="LanguageDropdown4928"
          value={lanugageInput}
          onChange={onChange}
          options={availableLanguages.map(({ label, value }) => ({
            label: t(label),
            value,
          }))}
        ></Dropdown>
      </div>
    </div>
  );
}
export default LanguageDropdown;
