import React from "react";

import {
  Button,
  createUseStyles,
  OverlayPanel,
  Toast,
  useNavigate,
  useUserLoggedIn,
  setUserLoggedInState,
  useUserLogOut,
  ROUTES,
  useIsMobile,
  // @ts-ignore
} from "@projectdiction/common-react";
import SettingsPanelMenu from "./SettingsPanelMenu";

const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  button: {
    marginLeft: 10,
  },
});

export default function PDMenubarEnd(props) {
  const classes = useStyles();

  const navigate = useNavigate();

  const toast = React.useRef<Toast>();

  const userLoggedIn = useUserLoggedIn();

  const op = React.useRef<OverlayPanel>();

  const logout = useUserLogOut();

  const isMobile = useIsMobile();

  const signOut = async () => {
    try {
      await logout();

      toast.current.show({
        severity: "success",
        summary: "Logged out",
        detail: "You have been logged out",
        life: 3000,
      });

      setUserLoggedInState(false);

      navigate(ROUTES.LOGIN);
    } catch (e) {
      toast.current.show({
        severity: "error",
        summary: "An error occurred while logging out",
        detail: e.message,
        life: 3000,
      });
    }
  };

  return (
    <>
      <div className="w-full h-full flex">
        <OverlayPanel ref={op}>
          <SettingsPanelMenu toast={toast} opRef={op} />
        </OverlayPanel>
        {userLoggedIn ? (
          <>
            <Button
              icon="pi pi-globe"
              className=""
              onClick={(e) => op.current.toggle(e)}
            />
            <Button
              icon="pi pi-sign-out"
              className=""
              iconPos="right"
              onClick={() => {
                signOut();
              }}
            />
          </>
        ) : (
          <>
            <Button
              icon="pi pi-sign-in"
              className={classes.button}
              iconPos="right"
              onClick={() => {
                navigate(ROUTES.LOGIN);
              }}
            />
            <Button
              icon="pi pi-user-plus"
              className={classes.button}
              iconPos="right"
              onClick={() => {
                navigate(ROUTES.SIGNUP);
              }}
            />
          </>
        )}
      </div>
      <Toast ref={toast} />
    </>
  );
}
