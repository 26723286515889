import React from "react";

import {
  useNavigate,
  TabMenu,
  // @ts-ignore
} from "@projectdiction/common-react";

import routes from "../../routes";
import PDMenubarEnd from "../PDMenubar/PDMenubarEnd";

export default function PDNavTabs() {
  const navigate = useNavigate();
  return (
    <TabMenu
      model={routes
        .map((route) => {
          return {
            icon: route.icon,
            command: (e) => {
              navigate(e.item.target);
            },
            target: route.route,
          };
        })
        .concat({
          // @ts-ignore
          template: () => {
            return <PDMenubarEnd />;
          },
        })}
    ></TabMenu>
  );
}
