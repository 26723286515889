import {
  ROUTES,
  // @ts-ignore
} from "@projectdiction/common-react";

interface RoutesType {
  title: string;
  route: string;
  icon: string;
  subroutes?: Array<RoutesType>;
}

const routes: Array<RoutesType> = [
  {
    title: "menubar.home",
    route: ROUTES.HOME,
    icon: "pi pi-language",
    subroutes: [
      {
        title: "menubar.home",
        route: ROUTES.HOME,
        icon: "pi pi-home",
      },
    ],
  },
  {
    title: "menubar.reading.label",
    route: ROUTES.READING.HOME,
    icon: "pi pi-book",
    subroutes: [
      { title: "menubar.home", route: ROUTES.READING.HOME, icon: "pi pi-home" },
      {
        title: "menubar.reading.lists.label",
        route: ROUTES.READING.READING_LIST.HOME,
        icon: "pi pi-list",
        subroutes: [
          {
            title: "menubar.reading.lists.add",
            route: ROUTES.READING.READING_LIST.ADD,
            icon: "pi pi-plus",
          },
          {
            title: "menubar.reading.lists.view",
            route: ROUTES.READING.READING_LIST.VIEW(),
            icon: "pi pi-eye",
          },
        ],
      },
      {
        title: "menubar.reading.categories.label",
        route: ROUTES.READING.CATEGORIES.HOME,
        icon: "pi pi-compass",
      },
    ],
  },
  {
    title: "menubar.writing.label",
    route: ROUTES.WRITING.HOME,
    icon: "pi pi-file-word",
    subroutes: [
      { title: "menubar.home", route: ROUTES.WRITING.HOME, icon: "pi pi-home" },
    ],
  },
  {
    title: "menubar.listening.label",
    route: ROUTES.LISTENING.HOME,
    icon: "pi pi-megaphone",
    subroutes: [
      {
        title: "menubar.home",
        route: ROUTES.LISTENING.HOME,
        icon: "pi pi-home",
      },
    ],
  },
  {
    title: "menubar.speaking.label",
    route: ROUTES.SPEAKING.HOME,
    icon: "pi pi-microphone",
    subroutes: [
      {
        title: "menubar.home",
        route: ROUTES.SPEAKING.HOME,
        icon: "pi pi-home",
      },
    ],
  },
  {
    title: "menubar.grammar.label",
    route: ROUTES.GRAMMAR.HOME,
    icon: "pi pi-key",
    subroutes: [
      { title: "menubar.home", route: ROUTES.GRAMMAR.HOME, icon: "pi pi-home" },
    ],
  },
  {
    title: "menubar.vocabulary.label",
    route: ROUTES.VOCABULARY.HOME,
    icon: "pi pi-database",
    subroutes: [
      {
        title: "menubar.home",
        route: ROUTES.VOCABULARY.HOME,
        icon: "pi pi-home",
      },
      {
        title: "menubar.vocabulary.dictionary.label",
        route: ROUTES.VOCABULARY.DICTIONARY.HOME(),
        icon: "pi pi-book",
      },
      {
        title: "menubar.vocabulary.categories.label",
        route: ROUTES.VOCABULARY.CATEGORIES.HOME,
        icon: "pi pi-compass",
      },
    ],
  },
  {
    title: "menubar.exercises.label",
    route: ROUTES.EXERCISES.HOME,
    icon: "pi pi-hammer",
    subroutes: [
      {
        title: "menubar.home",
        route: ROUTES.EXERCISES.HOME,
        icon: "pi pi-home",
      },
      {
        title: "menubar.exercises.fillblank.label",
        route: ROUTES.EXERCISES.FILLBLANK.HOME,
        icon: "pi pi-bars",
        subroutes: [
          {
            title: "menubar.exercises.fillblank.creator",
            route: ROUTES.EXERCISES.FILLBLANK.CREATOR.HOME,
            icon: "pi pi-lightbulb",
            subroutes: [
              {
                title: "menubar.exercises.fillblank.form",
                route: ROUTES.EXERCISES.FILLBLANK.CREATOR.FORM,
                icon: "pi pi-file",
              },
              {
                title: "menubar.exercises.fillblank.reading_item",
                route: ROUTES.EXERCISES.FILLBLANK.CREATOR.READING_ITEM(),
                icon: "pi pi-book",
              },
            ],
          },
          {
            title: "menubar.exercises.fillblank.solver",
            route: ROUTES.EXERCISES.FILLBLANK.SOLVE(),
            icon: "pi pi-lock-open",
          },
        ],
      },
    ],
  },
  {
    title: "menubar.courses.label",
    route: ROUTES.COURSES.HOME,
    icon: "pi pi-bolt",
    subroutes: [
      { title: "menubar.home", route: ROUTES.COURSES.HOME, icon: "pi pi-home" },
      {
        title: "menubar.courses.creator.label",
        route: ROUTES.COURSES.CREATOR.HOME,
        icon: "pi pi-palette",
        subroutes: [
          {
            title: "menubar.courses.creator.course.blank",
            route: ROUTES.COURSES.CREATOR.COURSE("newCourse"),
            icon: "pi pi-lightbulb",
          },
          {
            title: "menubar.courses.creator.screen.edit",
            route: ROUTES.COURSES.CREATOR.EDIT("editCourse"),
            icon: "pi pi-file-edit",
          },
        ],
      },
      {
        title: "menubar.courses.viewer.label",
        route: ROUTES.COURSES.VIEWER.HOME,
        icon: "pi pi-eye",
        subroutes: [
          {
            title: "menubar.home",
            route: ROUTES.COURSES.VIEWER.HOME,
            icon: "pi pi-home",
          },
        ],
      },
    ],
  },
];

export default routes;
