import React from "react";

import {
  Menubar,
  useNavigate,
  // @ts-ignore
} from "@projectdiction/common-react";

import routes from "../../routes";
// @ts-ignore
import { useTranslation } from "react-i18next";
import PDMenubarEnd from "./PDMenubarEnd";

function PDMenubar() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  function recursciveCreateMenuItems(routes, level = 0) {
    return routes.map((route) => {
      return {
        label: t(route.title),
        target: route.route,
        command: (e) => {
          const data = e.item.data;
          if (data.level === 0) return;
          navigate(e.item.target);
        },
        items: route.subroutes
          ? recursciveCreateMenuItems(route.subroutes, level + 1)
          : undefined,
        icon: route.icon,
        data: { level },
      };
    });
  }

  return (
    <Menubar model={recursciveCreateMenuItems(routes)} end={<PDMenubarEnd />} />
  );
}

export default PDMenubar;
