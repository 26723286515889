import {
  BrowserRouter,
  // @ts-ignore
} from "@projectdiction/common-react";
import PDMenubar from "./components/PDMenubar/PDMenubar";

// @ts-ignore
import { I18nextProvider } from "react-i18next";
// @ts-ignore
import i18n from "@projectdiction/translations";
import PDNavTabs from "./components/PDNavTabs/PDNavTabs";

export default function Root(props) {
  const isMobile = window.innerWidth < 768;
  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        {isMobile ? <PDNavTabs></PDNavTabs> : <PDMenubar></PDMenubar>}
      </BrowserRouter>
    </I18nextProvider>
  );
}
